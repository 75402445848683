import React from 'react'

function Contact() {
    return (
        <div>
            Contact
        </div>
    )
}

export default Contact;
