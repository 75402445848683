import { createStore, applyMiddleware, combineReducers } from 'redux'
import thunkMiddleware from 'redux-thunk';
import rootReducer from './Reducers';
import HomeReducer from './Reducers/ReducerData/HomeReducer.js';

const store = createStore(
    combineReducers({
        rootReducer: rootReducer,
        HomeReducer: HomeReducer
    }),
    applyMiddleware(thunkMiddleware)
)

export default store;